import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';

import { APOLLO_CLIENTS } from '../Services/apollo';

function ApolloClientProvider({ children }) {
  return (
    <ApolloProvider client={APOLLO_CLIENTS.LOAD}>
      {children}
    </ApolloProvider>
  );
}

export default ApolloClientProvider;

const FEATURE_FLAG = {
  ALLOW_EDIT_MM_DO_AFTER_CONFIRMATION: 'DEX-691_ALLOW_EDIT_MM_DO_AFTER_CONFIRMATION',
  CE_28_NFS_DEFAULT_ORDERS_LIST_DATE_FILTER: 'CE-28_NFS_DEFAULT_ORDERS_LIST_DATE_FILTER',
  DEFAULT_ROUTING_ADJUSTMENT_FOR_FIRST_MILE_SHIPPER: 'DEX_DEFAULT_ROUTING_ADJUSTMENT_FOR_FIRST_MILE_SHIPPER',
  DEX_USE_MAIN_COMPANY_KSUID_FOR_EMPLOYEES: 'DEX-USE_MAIN_COMPANY_KSUID_FOR_EMPLOYEES',
  DOOR_TO_DOOR_ORDER_CREATION_ON_NFS: 'DEX-12_DOOR_TO_DOOR_ORDER_CREATION_ON_NFS',
  ENABLE_NFS_LIMIT_REGISTERED_USER: 'DEX-625_NFS_LIMIT_REGISTERED_USER',
  FEX_1240_BULK_UPLOAD_NFS_DO: 'FEX-1240_BULK_UPLOAD_NFS_DO',
  FEX_858_NFS_DTD_TRACK_AND_TRACE: 'fex_858_nfs_dtd_track_and_trace',
  HIDE_SHIPPER_TYPES_CONFIG: 'DEX-656_HIDE_SHIPPER_TYPES_CONFIG',
  NFS_D2D_SHIPMENT_DETAIL_DOCUMENTS: 'KC-7143_NFS_D2D_SHIPMENT_DETAIL_DOCUMENTS',
  NFS_DTD_ACTIVITY_LOG: 'FEX-756_NFS_DTD_ACTIVITY_LOGS',
  NFS_PREMIUM_ACCESS: 'DEX-510_NFS_PREMIUM_ACCESS',
  WHITE_LIST_AVAILABLE_PORTS: 'DEX-384_WHITE_LIST_AVAILABLE_PORTS',
  WAG_DO_CREATOR_USER: 'wag_do_creator_user',
};

export default FEATURE_FLAG;

import colors from './colors';

export default {
  colors,
  fonts: ['Roboto', 'sans-serif'],
  fontSizes: {
    xsmall: '0.8rem',
    small: '0.9rem',
    medium: '1rem',
    large: '1.5rem',
    xlarge: '2rem'
  },
  layout: {
    mainLayoutSection: {
      padding: '0 4rem'
    }
  }
};
